// Generated by Framer (1e08357)

import { addFonts, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["VLEJy3JJ7"];

const serializationHash = "framer-Di1Tx"

const variantClassNames = {VLEJy3JJ7: "framer-v-8rrhlj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "VLEJy3JJ7", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={{webPageId: "augiA20Il"}}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-8rrhlj", className, classNames)} framer-7e2yfd`} data-framer-name={"Logo"} layoutDependency={layoutDependency} layoutId={"VLEJy3JJ7"} ref={ref ?? ref1} style={{...style}}><Image background={{alt: "", fit: "fill", pixelHeight: 324, pixelWidth: 1200, sizes: "120px", src: "https://framerusercontent.com/images/PMDZzRS4wVPMxGMwPUuGcuc0l3k.png", srcSet: "https://framerusercontent.com/images/PMDZzRS4wVPMxGMwPUuGcuc0l3k.png?scale-down-to=512 512w,https://framerusercontent.com/images/PMDZzRS4wVPMxGMwPUuGcuc0l3k.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/PMDZzRS4wVPMxGMwPUuGcuc0l3k.png 1200w"}} className={"framer-9mr6ge"} layoutDependency={layoutDependency} layoutId={"LOyTH7dAG"}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Di1Tx.framer-7e2yfd, .framer-Di1Tx .framer-7e2yfd { display: block; }", ".framer-Di1Tx.framer-8rrhlj { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-Di1Tx .framer-9mr6ge { flex: none; height: 28px; position: relative; width: 120px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Di1Tx.framer-8rrhlj { gap: 0px; } .framer-Di1Tx.framer-8rrhlj > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-Di1Tx.framer-8rrhlj > :first-child { margin-left: 0px; } .framer-Di1Tx.framer-8rrhlj > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 28
 * @framerIntrinsicWidth 120
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerDFuNbTlyP: React.ComponentType<Props> = withCSS(Component, css, "framer-Di1Tx") as typeof Component;
export default FramerDFuNbTlyP;

FramerDFuNbTlyP.displayName = "Components / Logo";

FramerDFuNbTlyP.defaultProps = {height: 28, width: 120};

addFonts(FramerDFuNbTlyP, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})